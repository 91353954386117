<template>
  <div
    class="w-100 mx-0 d-flex flex-column align-center"
    style="min-width: 200px;"
  >
    <div class="w-100 align-center d-flex flex-column pa-5">
      <admin-message-email-section v-if="category == 'Email'" />
      <admin-message-sms-section v-else-if="category == 'SMS'" />
      <!-- <admin-message-chat-section v-else-if="category == 'Chat'" /> -->
      <admin-message-history-section v-else-if="category == 'History'" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AdminMessageEmailSection from "./AdminMessageEmailSection.vue";
import AdminMessageSmsSection from "./AdminMessageSmsSection.vue";
// import AdminMessageChatSection from "./AdminMessageChatSection.vue";
import AdminMessageHistorySection from "./AdminMessageHistorySection.vue";

export default {
  components: {
    AdminMessageEmailSection,
    AdminMessageSmsSection,
    // AdminMessageChatSection,
    AdminMessageHistorySection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      category: "",
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type",
    }),
  },
  watch: {
    "$route.query.category": function(newValue) {
      this.category = newValue;
    },
  },
  mounted() {
    this.category = this.$route.query.category;
  },
};
</script>
