<template>
  <div class="w-100">
    <div class="d-flex flex-column mt-3">
      <v-card class="w-100">
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <div class="mr-2 mb-1 dark-font-color app-medium-font">
              To
            </div>
          </div>
          <div
            class="d-flex flex-wrap align-center px-3 py-1 to-sms mb-3"
            style="border-radius: 5px;"
          >
            <v-chip label small class="mr-3" v-for="chip in toArray" :key="chip"
              ><span class="pr-2">
                {{ chip }}
              </span>
              <v-icon small @click="onAddFans(chip)" v-if="isAgency(chip)">
                mdi-account-check
              </v-icon>
              <v-icon small @click="onCloseChip(chip)">
                $delete
              </v-icon>
            </v-chip>
            <div>
              <v-menu offset-y v-model="toSMSPopup">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchKey"
                    ref="toSMS"
                    hide-details
                    placeholder="Type to search"
                    solo
                    flat
                    dense
                    style="min-width: 100dp;"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                    @keyup.enter="onSelectFirstUser"
                  >
                  </v-text-field>
                </template>
                <div style="max-height: 60vh">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in filteredUsers"
                      :key="index"
                      class="to-sms-list-item py-1"
                    >
                      <v-list-item-title
                        v-if="item.header"
                        style="font-weight: bold;"
                        >{{ item.header }}</v-list-item-title
                      >
                      <v-list-item-title
                        v-else
                        @click="selectUser(item)"
                        class="d-flex"
                      >
                        <editable-avatar
                          :editable="false"
                          :image="item.thumbnail ? item.thumbnail : item.image"
                          :size="40"
                          :shadow="false"
                          class="mr-3"
                        />
                        <div class="d-flex flex-column">
                          <div>{{ item.name }}</div>
                          <div class="font-14 app-bold-font mt-1">
                            {{ formatPhoneNumber(item.phone) }}
                          </div>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="filteredUsers.length" />
                    <v-list-item @click="onSelectAll">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-account-group</v-icon>Select
                        All
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onSelectAllAgencies">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-domain</v-icon>Select All
                        Agencies
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onSelectAllVolunteers">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-account-multiple</v-icon>Select
                        All Volunteers
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="py-0">
          <div class="d-flex align-center">
            <div class="mr-2 mb-1 dark-font-color app-medium-font">
              From
            </div>
          </div>
          <v-text-field
            v-model="fromSMS"
            outlined
            dense
            readonly
            placeholder="Phone"
          />
        </v-card-text>
        <v-card-text class="pt-0">
          <v-textarea v-model="smsContent" outlined dense auto-grow rows="15" />
        </v-card-text>
        <v-card-actions class="mb-3 pl-5 d-flex justify-end">
          <v-btn @click="onDelete" v-if="$route.query._id" class="ml-3"
            >Delete</v-btn
          >
          <v-btn @click="onSend" :loading="loading" color="primary mr-3">
            {{ $route.query._id ? "Resend" : "Send" }}
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="errorMessage">
          <v-alert
            dense
            type="error"
            v-text="errorMessage"
            v-if="errorMessage"
          />
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseDialog"
        :onConfirm="onConfirmDelete"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";

export default {
  components: { EditableAvatar, ConfirmDeleteDialog },
  data() {
    return {
      toArray: [],
      fromSMS: "(925) 526-7354",
      smsContent: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      toSMSPopup: false,
      phoneTypeData: {},
      filteredUsers: [],
      searchKey: null,
      loading: false,
      deleteDialog: false,
      isDeleting: false,
      snackbar: false,
      snackMessage: null,
      errorMessage: null,
      errorDelete: null,
      errorDeleteMessage: null,
    };
  },
  methods: {
    ...mapActions({
      sendSMS: "auth/sendAdminSMS",
      searchUsers: "chat/searchUsersPhone",
      getMessageLog: "messagelog/getLogDetails",
      deleteMessageLog: "messagelog/deleteLog",
      getTypeFromPhone: "auth/getTypeFromPhone",
    }),
    onChange() {
      this.errorMessage = null;
      this.errorDelete = null;
      this.errorDeleteMessage = null;
    },
    onSend() {
      this.errorMessage = null;
      if (
        this.fromSMS &&
        this.toArray &&
        this.toArray.length &&
        this.smsContent
      ) {
        this.loading = true;
        this.sendSMS({
          from: this.fromSMS,
          toArray: this.toArray,
          content: this.smsContent,
        })
          .then((message) => {
            this.loading = false;
            this.toArray = [];
            this.smsContent = "";
            this.showSnackMessage(message);
            if (this.$route.query._id) {
              this.$router.back();
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      }
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      this.uploadBlogImage(formData)
        .then((res) => {
          Editor.insertEmbed(cursorLocation, "image", res);
          resetUploader();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    onCloseChip(chip) {
      this.toArray = this.toArray.filter((t) => t != chip);
    },
    onSelectFirstUser() {
      if (
        this.filteredUsers.length &&
        !this.toArray.includes(this.filteredUsers[1].phone)
      ) {
        this.toArray.push(this.filteredUsers[1].phone);
      }
      this.searchKey = "";
    },
    onSelectAll() {
      if (!this.toArray.includes("All")) {
        this.toArray.push("All");
      }
      this.searchKey = "";
      this.$refs.toSMS.$refs.input.focus();
    },
    onSelectAllAgencies() {
      if (!this.toArray.includes("All Agencies")) {
        this.toArray.push("All Agencies");
      }
      this.searchKey = "";
      this.$refs.toSMS.$refs.input.focus();
    },
    onSelectAllVolunteers() {
      if (!this.toArray.includes("All Volunteers")) {
        this.toArray.push("All Volunteers");
      }
      this.searchKey = "";
      this.$refs.toSMS.$refs.input.focus();
    },
    selectUser(item) {
      if (!this.toArray.includes(item.phone)) {
        this.toArray.push(item.phone);
      }
      this.searchKey = "";
      this.$refs.toSMS.$refs.input.focus();
    },
    showSnackMessage(message) {
      this.snackbar = true;
      this.snackMessage = message;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.isDeleting = true;
      this.deleteMessageLog({ _id: this.$route.query._id })
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.isDeleting = false;
          console.log(error.response.data.message);
        });
    },
    isAgency(phone) {
      if (this.phoneTypeData[phone] == undefined) {
        this.getTypeFromPhone(phone)
          .then((res) => {
            this.phoneTypeData[res.phone] = res.type;
            this.toArray = [...this.toArray];
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      } else if (this.phoneTypeData[phone] == "Agency") return true;
      return false;
    },
    onAddFans(phone) {
      if (!this.toArray.includes("Fans of " + phone))
        this.toArray.push("Fans of " + phone);
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
    }),
  },
  watch: {
    searchKey(newValue) {
      if (newValue) {
        this.searchUsers(newValue)
          .then((res) => {
            this.filteredUsers = res;
            console.log(this.filteredUsers);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
        this.toSMSPopup = true;
      }
    },
    toSMS(val, prev) {
      if (prev && val.length === prev.length) return;

      this.toSMS = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
          };

          this.items.push(v);
        }

        return v;
      });
    },
  },
  mounted() {
    if (this.$route.query._id) {
      this.loading = true;
      this.getMessageLog({ _id: this.$route.query._id })
        .then((res) => {
          this.loading = false;
          this.fromSMS = res.from;
          this.toArray = res.to;
          this.smsContent = res.content;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.loading = false;
        });
    }
  },
};
</script>
<style scoped>
.to-sms {
  border: 1px solid rgba(0, 0, 0, 0.42);
}
.to-sms:hover {
  border: 1px solid black;
}
.to-sms-list-item:hover {
  background-color: #f6f6f6;
}
</style>
