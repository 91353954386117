<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <admin-message-left-section />
        <admin-message-feed-section />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapGetters } from "vuex";
import AdminMessageFeedSection from "./AdminMessageFeedSection.vue";
import AdminMessageLeftSection from "./AdminMessageLeftSection.vue";
export default {
  components: {
    AdminMessageFeedSection,
    AdminMessageLeftSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
